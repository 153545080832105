@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";

* {
  padding: 0;
  margin: 0;
  max-height: inherit;
  box-sizing: border-box;
}

*::after,
*::before {
  box-sizing: border-box;
  max-height: inherit;
  padding: 0;
  margin: 0;
}

.loaderStyles {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #ffffff;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
}

@import "bootstrap/dist/css/bootstrap.min.css";

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
  border-color: #ced4da;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid #719ece;
  box-shadow: none;
}
